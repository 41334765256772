@import '~@angular/material/theming';

// fisrt: 8 is our magic number, 16, 24, 32, 40. like 3 can be. https://www.youtube.com/watch?v=aU4pyiB-kq0

$dark-text: #333333;

$dark-background:     #0a0a0a;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);

$light-primary: #051b71;
$dark-primary: #5375f7;

$light-accent: #369AD4;
$dark-accent: #369ad4;

$light-secondary: $light-accent;
$dark-accent: $dark-accent;

$table-font-size: 13px;
$default-font-size: 16px;
$base-grid-spacer: 8px;

// Neo Material font config overwrites for material.
// do not set variables in this config. It more readable like this. 
$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, 'Ubuntu', -0.0134em), // px to em 
  display-3: mat-typography-level(56px, 56px, 400, 'Ubuntu', -0.0089em),
  display-2: mat-typography-level(48px, 56px, 400, 'Ubuntu', -0.0083em),
  display-1: mat-typography-level(34px, 40px, 400, 'Ubuntu', 0.0074em), // h1
  headline: mat-typography-level(32px, 40px, 400, 'Ubuntu', -0.0031em), // h2
  title: mat-typography-level(21px, 32px, 500, 'Ubuntu', -0.0048em), // h3
  subheading-2: mat-typography-level(19px, 32px, 400, 'Ubuntu', -0.0063em),
  subheading-1: mat-typography-level(16px, 22px, 700, 'Ubuntu', 0.0000em),
  body-1: mat-typography-level(16px, 22px, 300, 'Ubuntu', 0.0000em),
  body-2: mat-typography-level(13px, 18px, 400, 'Ubuntu', 0.0000em),
  button: mat-typography-level(13px, 18px, 400, 'Ubuntu', 0.0000em),
  caption: mat-typography-level(11px, 18px, 400, 'Ubuntu', 0.0000em),
  input: mat-typography-level(inherit, 1.125, 400, 'Ubuntu', 1.5px), // do not change 1.125 line height
);

// Foreground Elements

// Light Theme Text
$dark-text: #333333;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:             #333333,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-primary-text,
  icons:             $dark-primary-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #f7f7f7;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base:              $light-text,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config

// Light bg
$light-background:    #ffffff;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#0a0a0a, 20%);
$dark-bg-alpha-4:     rgba(#0a0a0a, 0.04);
$dark-bg-alpha-12:    rgba(#0a0a0a, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #0a0a0a;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#ffffff, 0.04);
$light-bg-alpha-12:   rgba(#ffffff, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           $light-focused,
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);

$mat-primary: (
  50 : #e1e4ee,
  100 : #b4bbd4,
  200 : #828db8,
  300 : #505f9c,
  400 : #2b3d86,
  500 : $light-primary,
  600 : #041869,
  700 : #04145e,
  800 : #031054,
  900 : #010842,
  A100 : #777cff,
  A200 : #444bff,
  A400 : #111bff,
  A700 : #000af6,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$theme-primary: mat-palette($mat-primary);

$mat-accent: (
  50 : #e7f3fa,
  100 : #c3e1f2,
  200 : #9bcdea,
  300 : #72b8e1,
  400 : #54a9da,
  500 : $light-accent,
  600 : #3092cf,
  700 : #2988c9,
  800 : #227ec3,
  900 : #166cb9,
  A100 : #ebf5ff,
  A200 : #b8dbff,
  A400 : #85c2ff,
  A700 : #6cb5ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$theme-accent: mat-palette($mat-accent);

$mat-primary-dark: (
  50 : #eaeefe,
  100 : #cbd6fd,
  200 : #a9bafb,
  300 : #879ef9,
  400 : #6d8af8,
  500 : $dark-primary,
  600 : #4c6df6,
  700 : #4262f5,
  800 : #3958f3,
  900 : #2945f1,
  A100 : #777cff,
  A200 : #fcfcff,
  A400 : #c9cfff,
  A700 : #afb9ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$theme-primary-dark: mat-palette($mat-primary-dark);

$mat-warn: (
  main: #fa5c00,
  lighter: #feceb3,
  darker: #f84100,
  200: #fa5c00, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

$theme-warn: mat-palette($mat-warn, main, lighter, darker);
$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary-dark, $theme-accent, $theme-warn);

