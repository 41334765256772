// v6.8.3
.bpce-icon { 
  &.add:before { 
    content: "\EA01" 
  }

  &.browser:before { 
    content: "\EA03" 
  }

  &.calendar-ok:before { 
    content: "\EA04" 
  }

  &.calendar:before { 
    content: "\EA05" 
  }

  &.call:before { 
    content: "\EA06" 
  }

  &.card:before { 
    content: "\EA07" 
  }

  &.check-circle:before { 
    content: "\EA08" 
  }

  &.check:before { 
    content: "\EA09" 
  }

  &.checkbox-off:before { 
    content: "\EA0A" 
  }

  &.checkbox-on:before { 
    content: "\EA0B" 
  }

  &.close:before { 
    content: "\EA0C" 
  }

  &.cloud:before { 
    content: "\EA0D" 
  }

  &.download-cloud:before { 
    content: "\EA0E" 
  }

  &.download:before { 
    content: "\EA0F" 
  }

  &.edit:before { 
    content: "\EA10" 
  }

  &.email:before { 
    content: "\EA11" 
  }

  &.erase:before { 
    content: "\EA12" 
  }

  &.favoris:before { 
    content: "\EA13" 
  }

  &.folder:before { 
    content: "\EA14" 
  }

  &.geolocation-pin:before { 
    content: "\EA15" 
  }

  &.help:before { 
    content: "\EA16" 
  }

  &.information:before { 
    content: "\EA17" 
  }

  &.phone:before { 
    content: "\EA19" 
  }

  &.profil:before { 
    content: "\EA1A" 
  }

  &.refresh:before { 
    content: "\EA1B" 
  }

  &.remove:before { 
    content: "\EA1C" 
  }

  &.secure:before { 
    content: "\EA1D" 
  }

  &.share:before { 
    content: "\EA1E" 
  }

  &.trash:before { 
    content: "\EA1F" 
  }

  &.warning:before { 
    content: "\EA20" 
  }

  &.arrow-up:before { 
    content: "\EA22" 
  }

  &.arrow-down:before { 
    content: "\EA23" 
  }

  &.target:before { 
    content: "\EA24" 
  }

  &.camera:before { 
    content: "\EA25" 
  }

  &.find:before { 
    content: "\EA26" 
  }

  &.log-out:before { 
    content: "\EA27" 
  }

  &.page:before { 
    content: "\EA28" 
  }

  &.euro:before { 
    content: "\EA29" 
  }

  &.file-doc:before { 
    content: "\EA2A" 
  }

  &.file-pdf:before { 
    content: "\EA2B" 
  }

  &.file-ppt:before { 
    content: "\EA2C" 
  }

  &.file-xls:before { 
    content: "\EA2D" 
  }

  &.chat:before { 
    content: "\EA30" 
  }

  &.eye:before { 
    content: "\EA31" 
  }

  &.eye-close:before { 
    content: "\EA32" 
  }

  &.call-back:before { 
    content: "\EA33" 
  }

  &.support-help:before { 
    content: "\EA34" 
  }

  &.more-details:before { 
    content: "\EA35" 
  }

  &.chevron-down:before { 
    content: "\EA36" 
  }

  &.chevron-up:before { 
    content: "\EA37" 
  }

  &.chevron-left:before { 
    content: "\EA38" 
  }

  &.chevron-right:before { 
    content: "\EA39" 
  }

  &.alert:before { 
    content: "\EA3A" 
  }

  &.app-facebook:before { 
    content: "\EA3B" 
  }

  &.app-skype:before { 
    content: "\EA3C" 
  }

  &.app-twitter:before { 
    content: "\EA3D" 
  }

  &.app-video:before { 
    content: "\EA3E" 
  }

  &.upload-cloud:before { 
    content: "\EA40" 
  }

  &.money-out:before { 
    content: "\EA41" 
  }

  &.money-in:before { 
    content: "\EA42" 
  }

  &.delete:before { 
    content: "\EA43" 
  }

  &.list:before { 
    content: "\EA44" 
  }

  &.grid:before { 
    content: "\EA45" 
  }

  &.reach-the-start:before { 
    content: "\EA46" 
  }

  &.reach-the-end:before { 
    content: "\EA47" 
  }

  &.filter:before { 
    content: "\EA48" 
  }

  &.bulle:before { 
    content: "\EA49" 
  }

  &.molecule:before { 
    content: "\EA4A" 
  }

  &.plus:before { 
    content: "\EA4B" 
  }

  &.moins:before { 
    content: "\EA4C" 
  }

  &.expand:before { 
    content: "\EA4D" 
  }

  &.reduce:before { 
    content: "\EA4E" 
  }

  &.exchange:before { 
    content: "\EA50" 
  }

  &.call-out:before { 
    content: "\EA52" 
  }

  &.copy:before { 
    content: "\EA51" 
  }

  &.arrow-downleft:before { 
    content: "\EA53" 
  }

  &.arrow-downright:before { 
    content: "\EA54" 
  }

  &.arrow-left:before { 
    content: "\EA55" 
  }

  &.arrow-right:before { 
    content: "\EA56" 
  }

  &.arrow-upleft:before { 
    content: "\EA57" 
  }

  &.arrow-upright:before { 
    content: "\EA58" 
  }

  &.building:before { 
    content: "\EA59" 
  }

  &.calculator:before { 
    content: "\EA5A" 
  }

  &.cart:before { 
    content: "\EA5B" 
  }

  &.charts-bar:before { 
    content: "\EA5C" 
  }

  &.charts-linedown:before { 
    content: "\EA5D" 
  }

  &.charts-lineup:before { 
    content: "\EA5E" 
  }

  &.charts-pie:before { 
    content: "\EA5F" 
  }

  &.historic:before { 
    content: "\EA60" 
  }

  &.menu:before { 
    content: "\EA62" 
  }

  &.home:before { 
    content: "\EA61" 
  }

  &.more:before { 
    content: "\EA63" 
  }

  &.print:before { 
    content: "\EA64" 
  }

  &.cogwheel:before { 
    content: "\EA65" 
  }

  &.media-play:before { 
    content: "\EA66" 
  }

  &.media-pause:before { 
    content: "\EA67" 
  }

  &.media-duration:before { 
    content: "\EA68" 
  }

  &.repeat:before { 
    content: "\EA69" 
  }

  &.sun:before { 
    content: "\EA6A" 
  }

  &.moon:before { 
    content: "\EA6B" 
  }

  &.link:before { 
    content: "\EA6C" 
  }

  &.thumbs-up:before { 
    content: "\EA6D" 
  }

  &.thumbs-down:before { 
    content: "\EA6E" 
  }

  &.app-linkedin:before { 
    content: "\EA6F" 
  }

  &.app-pinterest:before { 
    content: "\EA70" 
  }

  &.app-instagram:before { 
    content: "\EA71" 
  }

  &.app-tiktok:before { 
    content: "\EA72" 
  }

  &.app-snapchat:before { 
    content: "\EA73" 
  }

  &.app-slack:before { 
    content: "\EA74" 
  }

  &.alert-active:before { 
    content: "\EA75" 
  }

  &.server:before { 
    content: "\EA76" 
  }

  &.box:before { 
    content: "\EA77" 
  }

  &.layers:before { 
    content: "\EA78" 
  }

  &.image:before { 
    content: "\EA79" 
  }

  &.shield:before { 
    content: "\EA7A" 
  }

  &.umbrella:before { 
    content: "\EA7B" 
  }

  &.upload:before { 
    content: "\EA7D" 
  }

  &.doc-alt:before { 
    content: "\EA7C" 
  }

  &.favoris-active:before { 
    content: "\EA7E" 
  }

  &.heart-active:before { 
    content: "\EA7F" 
  }

  &.checkbox-indeterminate:before { 
    content: "\EA81" 
  }

  &.subscribe:before { 
    content: "\EA80" 
  }

  &.envelope:before { 
    content: "\EA82" 
  }

  &.connections:before { 
    content: "\EA83" 
  }

  &.accessibility:before { 
    content: "\EA84" 
  }

  &.face-smiling:before { 
    content: "\EA85" 
  }

  &.face-neutral:before { 
    content: "\EA86" 
  }

  &.face-frowning:before { 
    content: "\EA87" 
  }

  &.face-smiling-active:before { 
    content: "\EA8B" 
  }

  &.face-frowning-active:before { 
    content: "\EA8D" 
  }

  &.face-neutral-active:before { 
    content: "\EA8C" 
  }

  &.unlink:before { 
    content: "\EA8F" 
  }

  &.tag:before { 
    content: "\EA90" 
  }

  &.mic:before { 
    content: "\EA91" 
  }

  &.list2:before { 
    content: "\EA92" 
  }
}
