
@import '~@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
// @import 'https://fonts.googleapis.com/css?family=Material+Icons+Outlined';
// @import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=auto');
@import './scss/icons';
@import './scss/icons-classes';
@import './variables';

// minimal 
body {
  padding: 0;
  margin: 0;
}

body {
  --bg-color: #ffffff;
  --bg-surface-color: #ffffff;
  --table-tr-highlight: #00000009;
  --primary-color: $light-primary;
  --primary-lighter-color: #b4bbd4;
  --primary-darker-color: #031054;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}


body {
  --accent-color: $light-accent;
  --accent-lighter-color: #c3e1f2;
  --accent-darker-color: #227ec3;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}


body {
  --warn-color: #fa5c00;
  --warn-lighter-color: #feceb3;
  --warn-darker-color: #f84100;
  --text-warn-color: #{$dark-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

// Compute font config
@include mat-core($fontConfig);

// Theme Init
@include angular-material-theme($theme);

.theme-container {
  padding: ($base-grid-spacer * 2);
  color: #333333;
  background-color: #ffffff;
}

.theme-alternate {
  // set color for dark mode
  --bg-color:#0a0a0a;
  --bg-surface-color: #101010;
  --table-tr-highlight: #ffffff22;
  --primary-color: $dark-primary;
  --primary-lighter-color: #b4bbd4;
  --primary-darker-color: #031054;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  
  @include angular-material-theme($altTheme);

  &.theme-container {
    color: #ffffff;
    background-color: #0a0a0a;
  }
}

body.theme-alternate {
  color: #ffffff;
    background-color: #0a0a0a;
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 46.8px; // optical alignement
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Outlined', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Ubuntu';
  }
}

// NEO specific 
.mat-typography p {
  margin: 0 0 ($base-grid-spacer * 2);
}

.bp-flat-button:not(.mat-button-disabled) {
  font-size: $default-font-size;
  line-height: 1.5rem;
  min-height: 24px;
  min-width: 180px;
  padding: $base-grid-spacer ($base-grid-spacer * 4);
}

.bp-stroked-button:not(.mat-button-disabled) {
  border-color: $light-primary;
  font-size: $default-font-size;
  line-height: 1.5rem; // px to rem why not? like neo vanilla? Kiss kiss neo
  min-height: 24px;
  min-width: 180px; // noe 
  padding: $base-grid-spacer ($base-grid-spacer * 4);
}

.bp-button-full {
  width: 100%;
  min-width: 0;
  padding: $base-grid-spacer ($base-grid-spacer * 2);
}

.bp-icon-button {
  .bpce-icon {
    margin-right: $base-grid-spacer;
  }
}

.bp-icon-button-end {
  .bpce-icon {
    margin-left: $base-grid-spacer;
  }
}
.bp-icon-button-shadow {
  min-width: 40px;
  min-height: 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
}

.bp-icon-button-shadow-container {
  margin: 0 0 0 ($base-grid-spacer * 2);
}

.bpce-icon {
  font-size: 20px;
}

.bp-flat-button {
  .bpce-icon {
    padding-right: $base-grid-spacer;
  }
}

// inputs Neo
.mat-form-field {
  letter-spacing: -0.01px;
}

.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    transition: border 0.3s linear;
    border: 1px solid #00000000;
  }
  &.mat-focused,
  &:hover {
    .mat-form-field-flex {
      border: 1px solid #00000033;
    }
  }
}

.mat-form-field {
  &.mat-focused {
    .mat-form-field-label {
      color: #00000099;
    }
  }
}


.mat-form-field.mat-focused {
  .mat-form-field-ripple {
    background-color: $light-primary;

    &.mat-accent {
      background-color: $light-accent;
    }

    // &.mat-warn {
    //   background-color: $underline-color-warn;
    // }
  }
}

.theme-alternate {
  .mat-form-field-appearance-fill {
    .mat-form-field-flex {
      border: 1px solid #ffffff00;
    }
    &.mat-focused,
    &:hover {
      .mat-form-field-flex {
        border: 1px solid #ffffff33;
      }
    }
  }
  
  .mat-form-field {
    &.mat-focused {
      .mat-form-field-label {
        color: #ffffff99;
      }
    }
  }

  .mat-form-field.mat-focused {
    .mat-form-field-ripple {
      background-color: $dark-primary;

      &.mat-accent {
        background-color: $dark-accent;
      }

      // &.mat-warn {
      //  background-color: $underline-color-warn;
      // }
    }
  }

}

// flex helper


.flex {
  display: flex;
}

.flex-warp-spaced {
  flex-wrap: wrap;
  justify-content: space-between;
}

.box {
  flex: auto;
}

.box-align-items-center {
  padding-top: .6rem;
}


.box100 {
  flex: 100%;
}

.box10 {
  width: calc(10% - #{$base-grid-spacer});
}

.box20 {
  width: calc(20% - #{$base-grid-spacer});
}

.box30 {
  width: calc(30% - #{$base-grid-spacer});
}

.box40 {
  width: calc(40% - #{$base-grid-spacer});
}

.box50 {
  width: calc(50% - #{$base-grid-spacer});
}

.box80 {
  width: calc(80% - #{$base-grid-spacer});
}

// 1/3

.box33 {
  width: calc(33.333333% - #{$base-grid-spacer});
}

.box66 {
  width: calc(66.666666% - #{$base-grid-spacer});
}


.box-center {
  text-align: center;
  padding-top: $base-grid-spacer;
  padding-bottom: $base-grid-spacer;
}

.step-dot {
  width: $base-grid-spacer;
  height: $base-grid-spacer;
  border-radius: 50%;
  margin-left: $base-grid-spacer * 2;
  border: 1px solid $light-bg-darker-20;
  background-color: white;
}

.step-input-indicator {
  min-width: $base-grid-spacer / 2;
  height: 61.5px; // yes do not remove the .5
  border-top-left-radius: 4px;
}

.rs-step-name.mat-form-field-appearance-fill .mat-form-field-flex {
  border-top-left-radius: 0;
}

.step-dot,
.step-input-indicator {
  
  &.step-x {
    border: 1px solid #cccccc00;
  }
  &.step-1 {
    background-color: #589496;
  }
  &.step-2 {
    background-color: #285066;
  }
  &.step-3 {
    background-color: #BD8A00;
  }
  &.step-4 {
    background-color: #00a193;
  }
  &.step-0 {
    background-color: #e6434b;
  }
}