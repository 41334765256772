// v6.8.3
@font-face {
  font-family: "symbols_89C3";
  src: url('../font/symbols_89C3.eot');
  src: url('../font/symbols_89C3.eot?#iefix') format('eot'),
    url('../font/symbols_89C3.woff2') format('woff2'),
    url('../font/symbols_89C3.woff') format('woff'),
    url('../font/symbols_89C3.ttf') format('truetype'),
    url('../font/symbols_89C3.svg#symbols_89C3') format('svg');
  font-weight: normal;
  font-style: normal;
}

.bpce-icon:before {
  display: inline-block;
  font-family: "symbols_89C3";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bpce-icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.bpce-icon.at2x { font-size: 2em; }
.bpce-icon.at3x { font-size: 3em; }
.bpce-icon.at4x { font-size: 4em; }
.bpce-icon.at5x { font-size: 5em; }
.bpce-icon.fw {
  width: 1.2857142857142858em;
  text-align: center;
}